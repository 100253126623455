@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixin.scss';

.block_custom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: $default-background-color;
  max-width: 1440px;
  width: calc(100% - 40px);
  margin: 0 auto;
  padding: 35px 20px 45px 20px;

  @include respond-to(768px) {
    background-color: $default-text-white-color;
    padding-bottom: 20px;
  }
}

.addPadding {
  padding-top: 110px;
}

.block_custom_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: -55px;
  max-width: 1252px;

  @include respond-to(1400px) {
    margin-right: -5px;
  }

  @include respond-to(1300px) {
    max-width: 95%;
  }

  @include respond-to(880px) {
    max-width: 100%;
  }
}

.block_custom_title {
  @include mediumText(60px, 85px);

  @include respond-to(980px) {
    @include mediumText(55px, 75px);
  }

  @include respond-to(768px) {
    @include mediumText(40px, 60px);
  }
}

.block_custom_element__title {
  text-align: center;
  @include mediumText(26px, 37px);
  margin: 0;
  margin-bottom: 10px;

  @include respond-to(1400px) {
    @include mediumText(20px, 30px);
  }

  @include respond-to(768px) {
    @include mediumText(16px, 19px);
  }
}

.edge_title {
  @extend .block_custom_element__title;
  text-align: start;
  max-width: 394px;
  margin-bottom: 15px;

  @include respond-to(720px) {
    margin-bottom: 10px;
  }
}

.block_custom_button {
  @include defaultStyleButton(24px, 40px, 24px, 40px);
  max-width: 276px;
  max-height: 64px;

  @include respond-to(768px) {
    display: none;
  }
}

.block_custom_element {
  background-color: $default-text-white-color;
  padding: 35px 80px;
  border-radius: 50px;

  @include respond-to(1400px) {
    padding: 20px 50px;
  }

  @include respond-to(768px) {
    border-radius: 24px;
    padding: 20px;
    background-color: $default-background-color;
  }
}

.block_custom_element__text {
  max-width: 355px;
  @include lightText;
  text-align: center;
  margin-top: 30px;

  @include respond-to(768px) {
    @include lightText(12px, 15px);
    margin-bottom: 0;
  }
}

.block_custom_element__describe {
  @include lightText;
  max-width: 394px;

  @include respond-to(1400px) {
    max-width: 305px;
  }

  @include respond-to(1200px) {
    max-width: 100%;
  }

  @include respond-to(768px) {
    @include lightText(12px, 15px);
    margin-bottom: 0;
    max-width: 95%;
  }
}

.block_custom_element__content {
  margin-left: 30px;
}

.vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding-top: 36px;
  min-height: 415px;
  width: calc(100% - 177px);

  @include respond-to(1400px) {
    width: calc(100%);
    height: 86.5%;
  }

  @include respond-to(1200px) {
    width: calc(100% - 40px);
    max-width: 738px;
    margin-bottom: 16px;
  }

  @include respond-to(820px) {
    min-height: 320px;
  }

  @include respond-to(768px) {
    padding: 20px 16px 16px 16px;
    width: calc(100% - 30px);
    min-height: 100%;
  }
}

.horisont {
  display: flex;
  align-items: center;
  max-width: 742px;
  width: calc(100% - 18px);
  padding: 48px;
  margin-bottom: 20px;

  @include respond-to(1400px) {
    width: calc(100%);
  }

  @include respond-to(1340px) {
    width: calc(100% - 70px);
  }

  @include respond-to(1050px) {
    justify-content: space-around;
  }

  @include respond-to(768px) {
    align-items: flex-start;
    width: fit-content;
    border-radius: 24px;
    padding: 16px 24px 16px 16px;
  }
}

.wrapper {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 1195px;
  margin: 0 auto;

  @include respond-to(1400px) {
    max-width: 100%;
    width: 100%;
  }

  @include respond-to(1200px) {
    flex-direction: column;
  }
}

.wrapper_horisont,
.wrapper_vertical {
  @include respond-to(1400px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }

  @include respond-to(1200px) {
    min-width: 95%;
  }

  @include respond-to(768px) {
    min-width: 100%;
  }
}

.block_custom__frame {
  width: 283px;
  height: 173px;

  @include respond-to(768px) {
    width: 188px;
    height: 128px;
  }
}

.block_custom__downLoadLogo {
  @include respond-to(768px) {
    width: 100px;
    height: 90px;
  }

  @include respond-to(512px) {
    width: 72px;
    height: 70px;
  }
}

.block_custom__redactor {
  @include respond-to(768px) {
    width: 100px;
    height: 90px;
  }

  @include respond-to(512px) {
    width: 72px;
    height: 58px;
  }
}
